@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply text-neutral-900;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  textarea:-webkit-autofill:active,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus,
  select:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
  }
}

@layer utilities {
  .live-player {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
  }
  .live-player iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
  }
  .blog-rich-text h2 {
    @apply my-5 text-3xl font-bold;
  }
  .blog-rich-text h3 {
    @apply my-4 text-2xl font-semibold;
  }
  .blog-rich-text h4 {
    @apply my-3 text-xl font-medium;
  }
  .blog-rich-text h5 {
    @apply my-2 text-lg font-medium;
  }
  .blog-rich-text h5 {
    @apply my-2 text-base font-medium;
  }
  .blog-rich-text p {
    @apply my-3;
  }
  .blog-rich-text ul {
    @apply ml-5 list-disc;
  }
  .blog-rich-text li {
    @apply mb-3;
  }
  .blog-rich-text figcaption,
  .blog-rich-text caption {
    @apply mb-4 -mt-2 text-sm text-center text-gray-600;
  }
  .blog-rich-text blockquote {
    @apply py-4 pl-4 my-6 text-lg font-medium border-l-8 rounded-md border-l-emerald-700;
  }
  .blog-rich-text img {
    @apply my-5;
  }
  .blog-rich-text .video-wrapper {
    @apply my-5 shadow-sm aspect-w-16 aspect-h-8;
  }
  .blog-rich-text .video-wrapper iframe {
    @apply rounded-md;
  }
  .tel-input-container {
    @apply whitespace-pre-wrap z-20 w-full font-sans text-sm !important;
  }
  .tel-input-dropdown {
    @apply rounded-md shadow-md border max-w-fit border-gray-300 -left-1 top-10 duration-100 transition-colors ease-out !important;
  }
  .tel-input-dropdown .country {
    @apply flex items-center !important;
  }
  .tel-input:focus {
    @apply border-primaryLight ring-1 ring-primaryLight !important;
  }
  .tel-input-button {
    @apply bg-white px-[7px] mx-2 my-3 border-b-0 border-t-0 border-l-0 border-r border-r-gray-300 !important;
  }

  .tel-input {
    @apply w-full px-[70px] py-6 text-base rounded-md border-gray-300 !important;
  }
  .no-scrollbar::-webkit-scrollbar {
    display: none;
    scroll-behavior: smooth;
  }
  .native-selecet-style {
    direction: rtl;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    scroll-behavior: smooth;
  }
  .tooltip-mobile {
    @apply hidden sm:flex bg-neutral-900 text-neutral-50 max-w-sm  !important;
  }

  /* Table Styling Start */
  .product-description .tg-wrap,
  .blog-rich-text .tg-wrap {
    @apply my-4;
  }
  .product-description .tg-wrap .tg-table thead tr th,
  .blog-rich-text .tg-wrap .tg-table thead tr th {
    @apply px-2 py-2 text-sm font-semibold text-gray-800 bg-gray-100 border border-gray-200 sm:px-3 sm:py-3 sm:text-base;
  }
  .product-description .tg-wrap .tg-table tbody tr td,
  .blog-rich-text .tg-wrap .tg-table tbody tr td {
    @apply px-2 py-2 text-xs text-gray-800 bg-gray-100 border border-gray-200 sm:px-3 sm:py-3 sm:text-sm;
  }
  .product-description .tg-wrap .tg-table tbody tr:nth-child(odd) td,
  .blog-rich-text .tg-wrap .tg-table tbody tr:nth-child(odd) td {
    @apply text-gray-700 bg-gray-50;
  }
  .product-description .tg-wrap .tg-table tbody tr th,
  .blog-rich-text .tg-wrap .tg-table tbody tr th {
    @apply px-2 py-2 text-sm font-semibold text-gray-800 bg-gray-100 border border-gray-200 sm:px-3 sm:py-3 sm:text-base;
  }
  .product-description .tg-wrap .tg-table tbody tr td:first-child,
  .blog-rich-text .tg-wrap .tg-table tbody tr td:first-child {
    @apply font-medium;
  }
  /* Table Styling End */
  .product-description h2 {
    @apply my-4 text-2xl font-semibold;
  }
  .product-description p {
    @apply mb-2;
  }

  .product-description h3 {
    @apply my-4 text-xl font-semibold;
  }
  .product-description h4 {
    @apply my-4 text-lg font-semibold;
  }

  .embla {
    position: relative;
    max-width: 670px;
    margin-left: auto;
    margin-right: auto;
  }

  .embla__viewport {
    overflow: hidden;
    width: 100%;
  }

  .embla__viewport.is-draggable {
    cursor: move;
    cursor: grab;
  }

  .embla__viewport.is-dragging {
    cursor: grabbing;
  }

  .embla__container {
    display: flex;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -webkit-tap-highlight-color: transparent;
    margin-left: -10px;
  }

  .embla__slide {
    position: relative;
    min-width: 100%;
    padding-left: 10px;
  }

  .embla__slide__inner {
    position: relative;
    overflow: hidden;
  }

  .embla__slide__img {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    width: auto;
    min-height: 100%;
    min-width: 100%;
    max-width: none;
    transform: translate(-50%, -50%);
  }

  .embla__button {
    outline: 0;
    cursor: pointer;
    background-color: transparent;
    touch-action: manipulation;
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    border: 0;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    fill: #1bcacd;
    padding: 0;
  }

  .embla__button:disabled {
    cursor: default;
    opacity: 0.3;
  }

  .embla__button__svg {
    width: 100%;
    height: 100%;
  }

  .embla__button--prev {
    left: 27px;
  }

  .embla__button--next {
    right: 27px;
  }
}
